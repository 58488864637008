<template>
    <vs-popup class="memberAdd_popup" title="Ajout" :active.sync="popupActive">
        <template v-if="loading==false">
            <div class="vx-row w-full">
                <div class="vx-col mb-6">
                    <vs-input label="Prénom" v-model="first_name" />
                </div>
                <div class="vx-col mb-6">
                    <vs-input  label="Nom" v-model="last_name" />
                </div>
            </div>


            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                <vue-tel-input class="w-full" v-model="phone"></vue-tel-input>
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                <vs-input class="w-full" label="Email (facultatif)" v-model="email" />
                </div>
            </div>
            
            <vs-divider>Provenance</vs-divider>

            <div v-for="channel in list_channel" :key="channel.uid">
            <vs-button :color="channel.color" type="filled" v-on:click="add( channel.uid )" class="w-full btn_channel">{{ channel.name }}</vs-button>
            </div>
            <vs-button color="primary" type="border" v-on:click="add(undefined)" class="w-full btn_channel">Aucune provenance</vs-button>
        </template>
        <template v-else>
            <h2 class="text-center center">Création du membre en cours ...</h2>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.memberAdd_popup
{
    .btn_channel{
        margin-bottom:5px;
    }
}
</style>

<script>

import Member  from '@/database/models/member';
import Channel  from '@/database/models/channel';
import Channel_link  from '@/database/models/channel_link';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,
            loading: false,

            first_name: '',
            last_name : '',
            phone     : '',
            email     : '',

            list_channel:[],
        }
    },
    watch:{
        $route (){
            this.loadChannel()
        },
    },
    beforeMount:function(){
        this.loadChannel()
    },
    methods:{
        openPopup( callback )
        {
            this.first_name = ''
            this.last_name  = ''
            this.phone      = ''
            this.email      = ''
            this.callback    = null
            this.popupActive = true
            this.loading     = false

            if(callback)
                this.callback = callback
        },
        add( channel_uid )
        {
            this.loading = true

            Member.add({
                first_name : this.first_name,
                last_name  : this.last_name,
                phone      : this.phone.split(' ').join(''),
                email      : this.email,
                active     : 1,
                type       : 'e',
            })
            .then((uid)=>{

                //fonction final event
                let fonc = ()=>
                {
                    //fermeture popup
                    this.popupActive = false

                    //callback event
                    if(this.callback)
                        this.callback(uid)

                    //Event refresh
                    this.$emit('refresh')
                }

                //Add channel
                if( channel_uid )
                Channel_link.add({
                    channel : channel_uid,
                    member  : uid,
                    /*booking : null,*/ // On la pas !
                })
                .then(fonc)
                .catch(fonc)
                else
                    fonc()
            })
            .catch(()=>
            {
                this.popupActive = false;
            });
        },

        loadChannel()
        {
            Channel.getTabAllMemory((list)=>{
                this.list_channel = list;
            })
        }
    }
}

</script>