<template>
    <vs-popup class="booking_popup" title="Réservation" :active.sync="popupActive">
        <vs-row>

            <vs-col vs-xs="12" vs-lg="6" style="padding-right:5px;">
                <div class="con-img img_posi">
                    <img  key="onlineImg" :src="photoURL" alt="user-img" width="80" height="80" class="rounded-full shadow-md cursor-pointer block center" v-on:click="openMember()" />
                </div>
                <div class="map" align="center">
                    <br>
                    <vs-button type="flat" class="btn-icon" @click="getLocation()"> <map-pin-icon size="2.5x" class="custom-class" ></map-pin-icon></vs-button>

                </div>
            </vs-col>
            <vs-col vs-xs="12" vs-lg="6" style="padding-right:5px;">
                <br/>
                <h2 class="cursor center" v-on:click="openMember()" >{{ getName() }}</h2>
                <div class="center" >{{ member.vest }} </div>
                <div class="center" >{{ getLocalPhone(member.phone) }}</div>
                <br/>
            </vs-col>

            <vs-col vs-xs="12" vs-lg="12" style="padding: 0 15px;">
                <vs-row vs-type="flex" vs-justify="space-between">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                        <div>
                            <label>Présent</label>
                            <vs-switch v-model="booking.venu" color="warning" v-on:change="bookingVenu()"/>
                        </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                        <div>
                            <label>Bilan</label>
                            <vs-switch v-model="booking.bilan" color="success" v-on:change="bookingBilan()" />
                        </div>
                    </vs-col>
                </vs-row>
                <vs-divider />
                <vs-row vs-type="flex" vs-justify="space-around">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" style="padding-right:5px;" >
                        <vs-button color="rgb(42, 199, 112)" type="filled" icon-pack="feather" icon="icon-move" size="large" v-on:click="deplacer()" aria-label="Déplacer"></vs-button>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" style="padding-left:5px;" >
                        <vs-button color="rgb(133, 198, 239)" type="filled" icon-pack="feather" icon="icon-copy" size="large" v-on:click="copier()" aria-label="Copier"></vs-button>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4" style="padding-left:5px;" >
                        <vs-button color="rgb(255, 146, 146)" type="filled" icon-pack="feather" icon="icon-trash-2" size="large" v-on:click="removeBooking()" aria-label="Supprimer"></vs-button>
                    </vs-col>
                </vs-row>
                <vs-divider />
                <vs-row vs-type="flex" vs-justify="space-around">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">  
                        <vs-button color="primary" type="filled" class="w-full mx-1" v-on:click="declaration()">Déclaration</vs-button><br/>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">  
                        <vs-button color="primary" type="filled" class="w-full mx-1" v-on:click="goFacture()">Facture</vs-button><br/>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">  
                        <vs-button color="primary" type="filled" class="w-full mx-1" v-on:click="goAbo()">Abonnement</vs-button><br/>
                    </vs-col>
                </vs-row>
            </vs-col>

            <vs-divider />
            
            <vs-col vs-xs="12" vs-lg="12">
                <h3 style="margin:20px;">Crédits</h3>
                <vs-row vs-type="flex" vs-justify="space-between" style="padding: 0 20px; text-aign: center;">
                    <ul>
                        <li v-for="(d, name, index) in this.credits" :key="index">
                            <b>{{ getMachineName(name) }}</b> &#10140; Restants : {{ d.credit }} / Utilisés : {{ d.used }}
                        </li>
                    </ul>
                </vs-row>
            </vs-col>

            <vs-divider />

            <vs-col vs-xs="12" vs-lg="12" vs-justify="center">
                <h3 style="margin:5px;text-align:center; ">Créance : {{ creance.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</h3>
            </vs-col>

            <vs-divider />

            <label>Ajouter un commentaire:</label>
            <vs-row vs-type="flex" vs-justify="space-between" style="margin-bottom:20px;">
                <vs-col vs-type="flex" vs-w="10">
                    <vs-textarea v-model="newCom" class="w-full" style="margin-right:10px;margin-bottom:0px;" height="50px"/>
                </vs-col>
                <vs-col vs-type="flex" vs-w="2">
                    <vs-button color="success" type="filled" v-on:click="addCom()">Ajouter</vs-button>
                </vs-col>
            </vs-row>

            <!-- HSITORIQUE COMMENTAIRE -->
            <listDate ref="ListCom" v-bind:reverse="1" v-bind:empty="true" v-bind:sep="0" class="w-full">

                <template v-slot:item="item">
                        <vx-card 
                            card-background = "#FCF7DE" 
                            content-color   = "#000" 
                            class="w-full"
                            >
                            <span>
                                <span v-html="item.item.data.commentary" ></span>
                            </span>
                            <span style="float:right;">{{ GetFullDate(item.item.data.createAt) }}</span>
                        </vx-card>
                        <br/>
                </template>

                <!-- HISTORIQUE VIDE -->
                <template v-slot:empty>
                    <br/>
                    <h3 style="text-align:center;">Aucun commentaire</h3>
                </template>
            </listDate>


        </vs-row>
    </vs-popup>
</template>

<script>
import Credit from '@/assets/utils/credit';
import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';
import Phone from '@/assets/utils/phone';
import Member  from '@/database/models/member';
import Booking  from '@/database/models/booking';
import Commentary  from '@/database/models/commentary';
import listDate from '@/components/app/listDate.vue'

import { MapPinIcon } from 'vue-feather-icons'


export default {
    
    components:{
        listDate,
        MapPinIcon
    },
  data() {
    return {
      uid         : undefined, 
      popupActive: false,
      member     : {},
      booking    : {},
      photoURL   : require("@/assets/images/portrait/small/avatar.png"),

      newCom     : '',

      credit     : 0,
      credit_used: 0,
      creance    : 0,

      credits    : {},
      items      : {},
      
      devise: {}
    }
  },
    mounted() {
        this.$srvApi.Req('get', 'public/currency')
        .then(( rep )=>{
            this.devise = rep.data;
        })
    },
    methods:{
        //localisation 
        getLocation(){
            // pour récuperer le type d'appareil 
            var device_type = navigator.userAgent
           // console.log(" is mobile ? ", device_type)
            let platform = window.navigator.platform;
            //console.log(" plateform : ", platform)
            let  macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
            
            // pour récuperer la marque de l'appareil 
            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(device_type)) {
                os = 'Android';
            } else if (!os && /Linux/.test(device_type)) {
                os = 'Linux';
            }      

            // lancer la bonne appli selon l'appareil utilisé : 

            // Google maps : 
            // https://www.google.com/maps/dir//19+Bd+Roger+Salengro,+84000+Avignon
            
            // Plans : 
            // http://maps.apple.com/?address=1,Infinite+Loop,Cupertino,California

            let url = this.member.address + " " + this.member.postal_code + " " + this.member.city;

            if( url!=null){
                if( os == 'Windows' || os == 'Android' || os == 'Linux' ){
                    window.open(
                        "https://www.google.com/maps/search/?api=1&query=" + url,
                        "_blank"
                    );
                }
                else if (os=='Mac OS' || os=='iOS' ){
                    window.open(
                        "http://maps.apple.com/?address="+url
                    );
                }
                else{
                    alert(" error")
                }
            }
            else{
                this.$vs.notify({
                        color:'danger',
                        title:'Localisation',
                        text:'Aucune adresse renseigné '
                    })
            }
              
        },
        
        getMachineName(nom) {
            let mach = this.items.find(machine => machine.groupe === nom)
            if(mach) return mach.name
            else return 'Global'
        },

        /* OPEN POPUP */
        openPopup( uid_booking )
        {
            this.newCom = '';
            this.credit = 0;
            this.credit_used = 0;
            this.uid = uid_booking;

            Booking.getMemoryClone( uid_booking, (booking)=>{
                this.booking = booking
                //load memory
                Member.getMemory(booking.member, async (member)=>
                {
                    this.member      = member
                    this.popupActive = true
                    this.photoURL    = this.$srvApi.buildUrl('studio/member/'+member.uid+'/photo');

                    // load crédit
                    
                    // let tab_credit   = await Credit.getCreditFiltre( member.uid, 'global' );
                    // this.credit      = tab_credit.credit;
                    // this.credit_used = tab_credit.used;
                    this.creance     = await Credit.getMemberCreance( member.uid );
                    let credits      = await Credit.getAllCredits( member.uid )
                    this.items       = credits.item
                    this.credits     = credits.credit
                })
                //load commentaire
                Commentary.getTabAllMemoryKey( booking.member, (list_com)=>
                {
                    //trier par date
                    list_com.sort(function(a, b)
                    {
                        var time_a  = (new Date(a.createAt)).getTime()
                        var time_b  = (new Date(b.createAt)).getTime()
                        return time_b - time_a;
                    });


                    let dcom = [];
                    for( var i=0; i<list_com.length && i<3; i++)

                    dcom.push({
                        date : new Date(list_com[i].createAt),
                        data : list_com[i],
                    });

                    this.$refs.ListCom.addTabMonth( dcom );
                })
            })
        },

        StrCapital( msg )
		{
			return Utils.StrCapital(msg);
        },
        GetFullDate:function(date)
        {
            return Fdate.getFullDateMin(date);
        },
        getLocalPhone:function(phone)
        {
            return Phone.getLocalPhone(phone);
        },
        getName(){
            return Utils.StrCapital(this.member.first_name+" "+this.member.last_name)
        },
        openMember:function()
        {
            this.popupActive = false
            
            if(this.member.uid)
            setTimeout(() => {
                    this.$router.push("/admin/member/"+this.member.uid).catch(()=>{})
            }, 250);
        },
        bookingVenu()
        {
            Booking.update( this.booking.uid, {'venu': this.booking.venu} )
            .then(function(){

            })
            .catch(function(){
                alert('Erreur change venu')
            })
        },
        bookingBilan()
        {
            Booking.update( this.booking.uid, {'bilan': this.booking.bilan} )
            .then(function(){
            })
            .catch(function(){
                alert('Erreur change bilan')
            })
        },

        removeBooking( select_uid )
        {
            let uid = this.booking.uid
            if(select_uid)
                uid = select_uid


            if(!window.confirm("Confirmer suppression ?"))
                return

            if(uid)
            {
                //Manuel suppression
                Booking.keyDelete( uid )
                //Event refresh
                this.$emit('refresh')
                //Envoi modif serveur
                Booking.remove( uid )
                .then(()=>{
                })
                .catch(()=>{
                    alert('Erreur suppression booking')
                })
                //fermeture popup
                this.popupActive = false
            }

            
        },
        copier()
        {
            //fermeture popup
            this.popupActive = false
            //Envoi event
            this.$emit('copier', this.member);
        },



        addCom()
        {
            if(this.newCom.length<3)
                alert('Commentaire trop court');
            
            Commentary.add({
                member    : this.member.uid,
                commentary: this.newCom
            })
            .then(()=>{
                //ok
                alert('Commentaire ajouté.');
                this.openPopup( this.uid );
            })
        },
        goFacture()
        {
            this.popupActive = false
            setTimeout(() => {
                this.$router.push("/admin/checkout/"+this.member.uid+'/fa').catch(()=>{})
            },250);
        },

        goAbo()
        {
            this.popupActive = false
            setTimeout(() => {
                this.$router.push("/admin/checkout/"+this.member.uid+'/abo').catch(()=>{})
            },250);
        },

        declaration()
        {
            this.popupActive = false
            setTimeout(() => {
                this.$router.push("/admin/declaration/"+this.member.uid).catch(()=>{})
            },250);
        },

        deplacer()
        {
            //fermeture popup
            this.popupActive = false
            //Envoi event
            this.$emit('move', this.uid );
        }
    }
}
</script>

<style scoped>

.map{
    visibility: hidden;
}

@media (max-width: 640px) {
	.map{
        visibility: visible;
    }
}

</style>